.css-feedaty_widget-vertical-reviews {
  & > div {
    max-width: 100% !important;
    & > div {
      max-width: 100%;
    }
  }
}
.css-feedaty_widget-recap {
  .fdt_productStriscia-slim-blk_widget_wrapper,
  img.fdt_productStriscia-slim-blk_stars {
    // max-width: 120px !important;
    // min-width: 120px !important;
  }
}

.css-feedaty_widget-recap .fdt_productStriscia-slim-blk_widget_product {
  width: 100% !important;
  max-width: 100% !important;
  display: block;
  height: auto !important;
  max-height: none !important;
  min-height: 0 !important;
  margin-bottom: 1rem;
}

.css-feedaty_widget-recap .fdt_productStriscia-slim-blk_widget_wrapper {
  max-width: none !important;
  min-width: 100%;
  width: max-content;
  flex-direction: row;
}

.css-feedaty_widget-recap img.fdt_productStriscia-slim-blk_stars {
  max-width: 100px !important;
  width: 100%;
  height: auto;
  min-width: 0 !important;
}

.fdt_productStriscia-slim-blk_widget_wrapper > a {
  gap: 0.5rem;
  pointer-events: none;
}

.fdt_productStriscia-slim-blk_ratx_star,
.fdt_productStriscia-slim-blk_widget_wrapper > a div {
  margin: 0 !important;
}

.fdt_productStriscia-slim-blk_txt_reviews {
  min-width: 0 !important;
  margin: 0 !important;
}
